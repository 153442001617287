import { Component, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { CALCULATOR_SECTION } from 'src/app/shared/consts';
import {
  EqueryTypes,
  Ecalculator,
  EtransaltionGroups,
  ElateralSteps,
} from 'src/app/shared/enum';
import { CalculatorService } from 'src/app/services/calculator.service';
import { LateralService } from 'src/app/services/lateral.service';
import { PopupTypes } from 'src/app/models/popuptype';
import { Router, NavigationEnd } from '@angular/router';
import { TextService } from 'src/app/services/text.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { of } from 'rxjs';
import { OfflineService } from 'src/app/services/offline.service';
import { CALCULATORS_INDEXES } from 'src/app/shared/consts'
import { LateralCalculationTypes, MatrixSelectionLimits } from 'src/app/shared/calculators/lateral/lateral.consts';
import { CalculatorSection } from 'src/app/shared/types';

@Component({
  selector: 'app-lateral-calculator-page',
  templateUrl: './lateral-calculator-page.component.html',
  styleUrls: ['./lateral-calculator-page.component.scss'],
})
export class LateralCalculatorPageComponent implements OnInit, AfterViewInit {
  @HostBinding('attr.style')
  mySubscription: any;
  user_id //for analytics events
  //popups
  warningPopup = PopupTypes.warning;
  errorPopup = PopupTypes.error;
  clearPopup = PopupTypes.clear;

  CALCULATOR_SECTION = CALCULATOR_SECTION;
  CALCULATOR_INDEX = CALCULATORS_INDEXES.LATERAL
  show_section: CalculatorSection = CALCULATOR_SECTION.EMITTER;
  EqueryTypes = EqueryTypes;
  Ecalculator = Ecalculator;
  ElateralSteps = ElateralSteps;
  EtransaltionGroups = EtransaltionGroups;
  showQueryTitle = false;

  //advanced-setting
  selectedCalculationMethod = '1';
  selectedFlushingMode = 'no';
  selectedPipeRoughness = '1';
  flushingValue: FormControl;
  pipe_roughness_value: FormControl;
  selectedFlushingTo = 'Atmosphere';
  flushingToOtherValue: FormControl;

  //emitter-block
  emitterForm: FormGroup;
  modelForm: FormGroup;
  emitter_type_options = [];

  //question-block
  QuestionsConfig = [];
  questionSelectedIndexType;
  questionSelectedIndex;
  str_id_step_text = [
    'lateral_s1_emitter',
    'lateral_s2_title',
    'lateral_s3_charac_title',
    'lateral_s4_title',
  ];

  // placeholder for matrix user input spacing/slopes
  // value will change according to current language
  userInputMatrixPlaceholder: string

  //lateral-block
  currentLateralSectionsForms: any[] = [];
  pressureForm: FormGroup;

  //elevation-block
  currentSlopeForms: FormGroup[] = [];
  matrixSlopeForm: FormGroup

  //results
  bodyRequestForCalculate;
  displayedColumns: string[] = [
    'pipe_section',
    'nominal_diameter',
    'wall_thickness',
    'length',
    'pressure_loss',
    'max_pressure',
    'min_pressure',
    'flow_rate',
    'velocity',
  ];
  tableDisplay = [];
  topResultBody: any[] = [];
  elevationTablePDF = [];
  calculator_results: any;
  show_result_button: boolean = false;
  matrixSelectionLimits = MatrixSelectionLimits
  isCalculateDisabled: boolean = false;

  constructor(
    public dataService: DataService,
    private fb: FormBuilder,
    public calculatorService: CalculatorService,
    public lateralService: LateralService,
    public router: Router,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService,
    public offlineService: OfflineService
  ) {
    this.dataService.clearLateralCharacteristcsData.subscribe((res) => {
      this.deleteAllLateralSection();
      if (
        this.dataService.checkIfItsInlineDripper(
          this.modelForm.controls.emitter_type.value.value
        )
      ) {
        this.emitterForm.reset();
      }
      this.pressureForm.reset();
      this.dataService.showClearPopup = false;
    });

    this.dataService.initiateQueries.subscribe((resp) => {
      this.QuestionsConfig = [];
      this.questionSelectedIndex = null;
      this.questionSelectedIndexType = null;
      this.calculator_results = null
    });
    this.dataService.changeToAnotherSectionInCalculator.subscribe((resp) => {
      this.changeFromResultToAnotherSection();
    });
    this.dataService.resetElevation.subscribe((res) => {
      this.clearFieldElevation();
    });
    this.dataService.getEndletPressureValidation.subscribe((res) => {
      this.getLastEmitterPressure();
    });
    this.dataService.resetLateralCalculator.subscribe((res) => {
      this.dataService.selectedTopography = 'Flat';
      location.reload()

    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
    this.dataService.calculateTotalLengthLateral.subscribe(res => {
      this.calculateTotalLateralLength()
    })
  }

  ngOnInit(): void {
    //init boolean inputs
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = ''

    this.dataService.selectedTopography = 'Flat';
    this.getPageData();
    this.setUserInputMatrixPlaceholder()
    this.initFlushingInput();
    this.initPipeRoughnessInit();
    this.initFlushingToOtherValue();
    this.initEmitterForm();
    this.initPressureForm();
    // this.initMatrixSlopeForm()
    this.currentLateralSectionsForms = [];
    this.addLateralSection();
  }

  initOnSelectQuestion() {
    this.show_result_button = false;
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = "";
    this.dataService.selectedTopography = "Flat";
    this.initFlushingInput();
    this.initPipeRoughnessInit();
    this.initFlushingToOtherValue();
    if (
      this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value
      )
    ) {
      this.initEmitterFormWithOutModel();
    }
    this.initPressureForm();
    this.currentLateralSectionsForms = [];
    this.addLateralSection();
  }

  onMatrixUserInputChange(
    control: AbstractControl,
    value: number,
    min_max_validation_values: { min: number; max: number },
    inputField: "slope" | "spacing" = null
  ) {

    this.dataService.inputs_contents_has_changed = true
    if (value) {
      if (inputField === "slope") {
        this.checkRecommendedSlopeRange(value);
      }
      const { min, max } = min_max_validation_values;
      control.setValidators([
        Validators.compose([
          Validators.required,
          Validators.pattern("[-+]?[0-9]+(.[0-9][0-9]?)?"),
          Validators.min(min),
          Validators.max(max),
        ]),
      ]);
      control.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      control.clearValidators();
      control.updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  checkRecommendedSlopeRange(value: number) {
    if (value > 10 || value < -10) {
      this.dataService.showAlertMessage = true;
      this.dataService.alert_msg_text = this.textService.getText(
        EtransaltionGroups.LATERAL,
        "lateral_matrix_slope_range"
      );
    } else if (this.dataService.showAlertMessage) {
      this.dataService.showAlertMessage = !this.dataService.showAlertMessage;
      this.dataService.inputs_contents_has_changed = false;
      this.dataService.alert_msg_text = "";
    }
  }

  setUserInputMatrixPlaceholder() {
    this.userInputMatrixPlaceholder = this.textService.getText(
      EtransaltionGroups.LATERAL,
      "lateral_matrix_user_input_placeholder"
    );
  }

  addMatrixUserInputsListeners() {
    this.emitterForm.controls.user_matrix_spacing_input.valueChanges.subscribe(
      (value) => {
        if (this.emitterForm.controls.spacing_matrix.value.length > this.matrixSelectionLimits.matrixSpacingLimit - 1) {
          this.emitterForm.controls.user_matrix_spacing_input.setValue(null, { emitEvent: false })
          return
        }
        this.onMatrixUserInputChange(
          this.emitterForm.controls.user_matrix_spacing_input,
          value,
          { min: 0.001, max: 100 }
        );
      }
    );
    this.matrixSlopeForm.controls.user_matrix_slope_input.valueChanges.subscribe(
      (value) => {
        if (this.matrixSlopeForm.controls.slopes_matrix.value.length > this.matrixSelectionLimits.matrixSlopesLimit - 1) {
          this.matrixSlopeForm.controls.user_matrix_slope_input.setValue(null, { emitEvent: false })
          return
        }
        this.onMatrixUserInputChange(
          this.matrixSlopeForm.controls.user_matrix_slope_input,
          value,
          { min: -100, max: 100 },
          'slope'
        );
      }
    );
  }

  addMaxAllowedPressureLossListener() {
    this.QuestionsConfig[this.questionSelectedIndex].control.valueChanges.subscribe(() => {
      this.dataService.inputs_contents_has_changed = true;
    })
  }

  addInletOutletPressureListeners() {
    this.pressureForm.controls.inlet_pressure.valueChanges.subscribe((val) => {
      if (!val) {
        this.matrixSlopeForm.controls.slopes_matrix.setValue([], {
          emitEvent: false,
        });
        this.matrixSlopeForm.controls.user_matrix_slope_input.setValue(null, { emitEvent: false })
      }
    });
    this.pressureForm.controls.end_pressure.valueChanges.subscribe((val) => {
      if (!val) {
        this.matrixSlopeForm.controls.slopes_matrix.setValue([], {
          emitEvent: false,
        });
        this.matrixSlopeForm.controls.user_matrix_slope_input.setValue(null, { emitEvent: false })
      }
    });
  }

  analyticsEvent() {
    if (localStorage.getItem('lateral_view')) {
      let views_count_by_user = Number(localStorage.getItem('lateral_view'));
      if (views_count_by_user == 1) {
        this.analyticsService.sendEvent(
          'lateral',
          'recurring_user_second_time',
          'recurring_user', this.user_id
        );
      }
      if (views_count_by_user == 4) {
        this.analyticsService.sendEvent(
          'lateral',
          'recurring_user_fifth_time',
          'recurring_user', this.user_id
        );
      }
      views_count_by_user++;
      localStorage.setItem('lateral_view', views_count_by_user.toString());
    } else {
      localStorage.setItem('lateral_view', '1');
    }
  }

  getTexts() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = this.textService
        .getResourceTextOffline()
      this.setTextResponse({ result: response, success: response ? true : false })
      this.dataService.showLoader = false;
    } else {
      this.textService
        .getResourceText(this.dataService.selected_language.row_id)
        .subscribe(res => {
          this.dataService.showLoader = false;
          this.setTextResponse(res)
        });
    }
  }

  setTextResponse(res) {
    if (res.success) {
      this.textService.translation_text = res.result.translations ? res.result.translations : res.result;
      localStorage.setItem(
        'resource_txt',
        JSON.stringify(this.textService.translation_text)
      );
      this.textService.setUnitsDictionary();

      this.dataService.logic_text_or = this.textService
        .getText(this.EtransaltionGroups.SUBMAIN, 'submain_or_1')
        .toString();
    }
  }
  setResultText() {
    this.elevationTablePDF = [
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.LATERAL,
            'lateral_summary_length_on_map'
          ),
          'm'
        ),
        key: 'mapLength',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.LATERAL,
            'lateral_summary_hight_dif_meters'
          ),
          'm'
        ),
        key: 'heightDiffM',
      },
      {
        display: this.textService.getText(
          EtransaltionGroups.LATERAL,
          'lateral_summary_hight_dif_precent'
        ),
        key: 'heightDiffP',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.LATERAL,
            'lateral_summary_real_length'
          ),
          'm'
        ),
        key: 'realLength',
      },
    ];

    this.tableDisplay = [
      { display: '', name: 'pipe_section', key: '' },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_nominal_diameter'
          ),
          'mm'
        ),
        name: 'nominal_diameter',
        key: 'NominalDiameter',
      },
      {
        display: this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          this.dataService.checkIfItsInlineDripper(
            this.dataService.chosen_emitter_type
          )
            ? 'lateral_results_wall_thickness'
            : 'lateral_results_sections_class'
        ),
        name: 'wall_thickness',
        key: 'Class',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_length'
          ),
          'm'
        ),
        name: 'length',
        key: 'SectionLength',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_pressure_loss'
          ),
          'm_pressure'
        ),
        name: 'pressure_loss',
        key: 'PressureLoss',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_max_pressure'
          ),
          'm_pressure'
        ),
        name: 'max_pressure',
        key: 'MaxPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_min_pressure'
          ),
          'm_pressure'
        ),
        name: 'min_pressure',
        key: 'MinPressure',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_max_flow_rate'
          ),
          'lh'
        ),
        name: 'flow_rate',
        key: 'MaxFlowRate',
      },
      {
        display: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_max_velocity'
          ),
          'ms'
        ),
        name: 'velocity',
        key: 'MaxVelocity',
      },
    ];

    if (this.dataService.isItMobile) {
      this.tableDisplay.splice(0, 1);
      this.displayedColumns.splice(0, 1);
    }

    this.topResultBody = [
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_total_length'
          ),
          'm'
        ),
        key: 'total_length',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_pressure_loss'
          ),
          'm_pressure'
        ),
        key: 'pressure_loss',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_inlet_flow_rate'
          ),
          'lh'
        ),
        key: 'inlet_flow_rate',
      },
      {
        name:
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_travel_time'
          ) +
          ' ' +
          this.textService.getText(this.EtransaltionGroups.UNITS, 'minuts'),
        key: 'travel_time',
      },
      {
        name: this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          'lateral_results_fv'
        ),
        key: 'flow_variation',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_inlet_pressure'
          ),
          'm_pressure'
        ),
        key: 'inlet_pressure',
      },
      {
        name: this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          'lateral_results_num_of_laterals'
        ),
        key: 'num_of_emitters',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_max_velocity'
          ),
          'ms'
        ),
        key: 'max_velocity',
      },
      {
        name: this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          'lateral_results_eu'
        ),
        key: 'emission_uniformity',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_end_pressure'
          ),
          'm_pressure'
        ),
        key: 'end_pressure',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_avg_emitter_flow_rate'
          ),
          'lh'
        ),
        key: 'emitter_avg_flow_rate',
      },

      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_sections_max_flow_rate'
          ),
          'lh'
        ),
        key: 'max_flow_rate',
      },

      {
        name: this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          'lateral_results_kd'
        ),
        key: 'emitter_kd',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_max_pressure'
          ),
          'm_pressure'
        ),
        key: 'max_pressure',
      },
      {
        name: this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_min_pressure'
          ),
          'm_pressure'
        ),
        key: 'min_pressure',
      },
      {
        name: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_results_slope'
          ),
        key: 'slope_percent',
      },
    ];
  }

  ngAfterViewInit() {
  }

  async getPageData() {
    this.dataService.showLoader = true;
    if (this.offlineService.isItOfflineMode) {
      let response = await this.calculatorService.getCalculatorPageDataLateralOffline()
      this.dataService.showLoader = false
      this.setResponsePageData({ result: response, success: response.page_data.lateral_charactaristics.emitter_types ? true : false })
    } else {
      this.calculatorService.getCalculatorPageDataLateral().subscribe((resp) => {
        this.dataService.showLoader = false;
        this.setResponsePageData(resp)

      });
    }

  }

  setResponsePageData(resp) {
    if (resp.success) {
      //analyticsEvents
      this.user_id = resp.result.page_data.lateral_charactaristics.user.id
      this.analyticsEvent()
      this.dataService.user_name =
        resp.result.page_data.lateral_charactaristics.user.first_name +
        ' ' +
        resp.result.page_data.lateral_charactaristics.user.last_name;
      this.dataService.regions_list =
        resp.result.page_data.lateral_charactaristics.regions;

      this.dataService.selected_region = this.dataService.regions_list.find(
        (value) => {
          return (
            resp.result.page_data.lateral_charactaristics.user.region ===
            value.region_id
          );
        }
      );
      this.dataService.selected_units = this.dataService.units_list.find(
        (value) => {
          return (
            value.value ===
            Number(resp.result.page_data.lateral_charactaristics.user.units)
          );
        }
      );
      this.dataService.languages_list =
        resp.result.page_data.lateral_charactaristics.languages;
      this.dataService.selected_language =
        this.dataService.languages_list.find((value) => {
          return (
            value.row_id ===
            resp.result.page_data.lateral_charactaristics.user.language.toString()
          );
        });

      this.getTexts();
      this.emitter_type_options =
        resp.result.page_data.lateral_charactaristics.emitter_types;

      this.dataService.inline_emitters_list =
        resp.result.page_data.lateral_charactaristics.emitters.inline_emitters;
      this.dataService.online_emitters_list =
        resp.result.page_data.lateral_charactaristics.emitters.online_emitters;
    }
  }

  initFlushingInput() {
    this.flushingValue = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
  }

  initPipeRoughnessInit() {
    this.pipe_roughness_value = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
  }

  initFlushingToOtherValue() {
    this.flushingToOtherValue = new FormControl('', {
      validators: [],
      updateOn: 'blur',
    });
  }

  initEmitterForm() {
    this.modelForm = this.fb.group({
      emitter_type: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      model: [
        '',
        {
          validators: [Validators.required, this.isModelExist()],
          // updateOn: 'blur',
        },
      ],
    });
    this.emitterForm = this.fb.group({
      flow_rate: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      spacing: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(0.001),
            Validators.max(100),
          ],
          updateOn: 'blur',
        },
      ],
      ...((!this.dataService.isItMobile && this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value)) && {
        spacing_matrix: [
          [],
          {
            validators: [
              Validators.required,
              Validators.minLength(1)
            ],
          },
        ]
      }),
      ...((!this.dataService.isItMobile && this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value)) && {
        user_matrix_spacing_input: [
          null,
          {
            validators: [Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'), Validators.min(0.001), Validators.max(100)],
            updateOn: 'blur',
          },
        ]
      }),
    }
    );
  }



  isCalculateButtonDisabled() {

    const isCalculateDisabled =
      !this.modelForm.valid ||
      !this.isLateralSectionFormValid() ||
      !(
        this.questionSelectedIndexType !== this.EqueryTypes.matrix && this.dataService.selectedTopography === 'Flat' ||
        this.isSlopesFormValid()
      ) || !this.isLateralSectionsValid() ||
      !this.pressureForm.valid ||
      !this.isEmitterFormValid() ||
      ((this.QuestionsConfig.length > 0 && (!this.QuestionsConfig[0]?.control.valid ||
        !this.QuestionsConfig[0]?.control_2?.valid) &&
        !this.QuestionsConfig[1]?.control.valid && !this.QuestionsConfig[2]?.control.valid) || this.QuestionsConfig.length === 0) || (this.flushingValue && !this.flushingValue?.valid && this.selectedFlushingMode == 'yes')
    return isCalculateDisabled
  }

  isLateralSectionFormValid() {
    const isAnySectionInvalid = this.currentLateralSectionsForms.some((section) => {
      return section.status === 'INVALID' || (section.status === 'VALID' && section.controls.length.value == null) || (section.status === 'VALID' && section.controls.length.value == '');
    });
    if (this.questionSelectedIndex === 0) {
      return true;
    }
    return !isAnySectionInvalid
  }
  // there is an initEmitter function which also init the model form, this is bad practice
  // for now leave the function as is and create a new function only for emitter form
  initEmitterFormWithOutModel() {
    this.emitterForm = this.fb.group({
      flow_rate: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      spacing: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(0.001),
            Validators.max(100),
          ],
          updateOn: 'blur',
        },
      ],
      ...((!this.dataService.isItMobile && this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value)) && {
        spacing_matrix: [
          [],
          {
            validators: [
              Validators.required,
              Validators.minLength(1)
            ],
          },
        ]
      }),
      ...((!this.dataService.isItMobile && this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value)) && {
        user_matrix_spacing_input: [
          null,
          {
            validators: [Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'), Validators.min(0.001), Validators.max(100)],
            updateOn: 'blur',
          },
        ]
      }),
    }
    );
  }

  next_to_queries() {
    if (this.QuestionsConfig.length === 0) {
      this.initializeQuestionsConfig();
    }

    //initate results titles after choosing emmiter type
    this.setResultText();
  }

  initializeQuestionsConfig() {
    //for PC
    if (this.dataService.chosen_model_object.isPC) {
      this.QuestionsConfig = [
        {
          title: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_pc'
          ),
          subTitle: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_pc_discription'
          ),
          placeholder: this.textService.addMesaureUnitsToLabel(
            this.textService.getText(
              this.EtransaltionGroups.LATERAL,
              'lateral_s2_max_length_pc_max_pressure_loss'
            ),
            'm_pressure'
          ),
          hint: '',

          isSelected: false,
          isSimple: false,
          query_type: this.EqueryTypes.maximum_length,
          control: new FormControl('', {
            validators: [
              Validators.required,
              Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
              Validators.min(0.1),
            ],
            updateOn: 'blur',
          }),
          control_2: new FormControl('', {
            validators: [],
            updateOn: 'blur',
          }),
        },
        {
          title: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_pressure_loss_pc'
          ),
          subTitle: this.textService.getText(
            EtransaltionGroups.LATERAL,
            'lateral_calcualtion_Q_pressure_subtitle'
          ),
          placeholder: '',
          hint: '',
          isSelected: false,
          isSimple: true,
          query_type: this.EqueryTypes.pressure_loss,
          control: new FormControl('', {
            validators: [Validators.requiredTrue],
            updateOn: 'blur',
          }),
        },
        // ...!this.dataService.isItMobile && this.dataService.checkIfItsInlineDripper(
        //   this.modelForm.controls.emitter_type.value.value) ? [{
        //     title: this.textService.getText(
        //       this.EtransaltionGroups.LATERAL,
        //       'lateral_s2_matrix'
        //     ),
        //     subTitle: this.textService.getText(
        //       EtransaltionGroups.LATERAL,
        //       'lateral_calcualtion_Q_matrix_subtitle'
        //     ),
        //     placeholder: this.textService.addMesaureUnitsToLabel(
        //       this.textService.getText(
        //         this.EtransaltionGroups.LATERAL,
        //         'lateral_s2_max_length_pc_max_pressure_loss'
        //       ),
        //       'm_pressure'
        //     ),
        //     hint: '',
        //     isSelected: false,
        //     isSimple: false,
        //     query_type: this.EqueryTypes.matrix,
        //     control: new FormControl('', {
        //       validators: [
        //         Validators.required,
        //         Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
        //         Validators.min(0.1),
        //       ],
        //       updateOn: 'blur',
        //     }),
        //     control_2: new FormControl('', {
        //       validators: [],
        //       updateOn: 'blur',
        //     }),
        //   }] : [],
      ];
    } else {
      //foe non-pc
      this.QuestionsConfig = [
        {
          title: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_non_pc'
          ),
          subTitle: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_non_pc_discription'
          ),
          placeholder: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_non_pc_fv'
          ),
          subTitle_2: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_non_pc_discription'
          ),
          placeholder_2: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_max_length_non_pc_eu'
          ),
          hint: '',
          isSelected: false,
          isSimple: false,
          query_type: this.EqueryTypes.maximum_length,
          control: new FormControl('', {
            validators: [
              Validators.requiredTrue,
              Validators.min(0),
              Validators.max(15),
            ],
            updateOn: 'blur',
          }),
          control_2: new FormControl('', {
            validators: [
              Validators.requiredTrue,
              Validators.min(80),
              Validators.max(100),
            ],
            updateOn: 'blur',
          }),
          radioButtons: new FormControl('', {
            validators: [Validators.required],
          }),
        },
        {
          title: this.textService.getText(
            this.EtransaltionGroups.LATERAL,
            'lateral_s2_fv_and_eu_non_pc'
          ),
          subTitle: this.textService.getText(
            EtransaltionGroups.LATERAL,
            'lateral_calcualtion_Q_pressure_subtitle'
          ),
          placeholder: '',
          hint: '',
          tooltipRows: [
            this.textService.getText(
              this.EtransaltionGroups.LATERAL,
              'lateral_s2_fv_and_eu_non_pc_tooltip1'
            ),
            this.textService.getText(
              this.EtransaltionGroups.LATERAL,
              'lateral_s2_fv_and_eu_non_pc_tooltip2'
            ),
            this.textService.getText(
              this.EtransaltionGroups.LATERAL,
              'lateral_s2_fv_and_eu_non_pc_tooltip3'
            ),
          ],
          isSelected: false,
          isSimple: true,
          query_type: this.EqueryTypes.flow_variation_and_emission_uniformity,
          control: new FormControl('', {
            validators: [Validators.requiredTrue],
            updateOn: 'blur',
          }),
        },
        // ...!this.dataService.isItMobile && this.dataService.checkIfItsInlineDripper(
        //   this.modelForm.controls.emitter_type.value.value) ? [{
        //     title: this.textService.getText(
        //       this.EtransaltionGroups.LATERAL,
        //       'lateral_s2_matrix'
        //     ),
        //     subTitle: this.textService.getText(
        //       this.EtransaltionGroups.LATERAL,
        //       'lateral_calcualtion_Q_matrix_subtitle'
        //     ),
        //     placeholder: this.textService.getText(
        //       this.EtransaltionGroups.LATERAL,
        //       'lateral_s2_max_length_non_pc_fv'
        //     ),
        //     subTitle_2: this.textService.getText(
        //       this.EtransaltionGroups.LATERAL,
        //       'lateral_s2_max_length_non_pc_discription'
        //     ),
        //     placeholder_2: this.textService.getText(
        //       this.EtransaltionGroups.LATERAL,
        //       'lateral_s2_max_length_non_pc_eu'
        //     ),
        //     hint: '',
        //     isSelected: false,
        //     isSimple: false,
        //     query_type: this.EqueryTypes.matrix,
        //     control: new FormControl('', {
        //       validators: [
        //         Validators.requiredTrue,
        //         Validators.min(0),
        //         Validators.max(15),
        //       ],
        //       updateOn: 'blur',
        //     }),
        //     control_2: new FormControl('', {
        //       validators: [
        //         Validators.requiredTrue,
        //         Validators.min(80),
        //         Validators.max(100),
        //       ],
        //       updateOn: 'blur',
        //     }),
        //     radioButtons: new FormControl('', {
        //       validators: [Validators.required],
        //     }),
        //   }] : [],
      ];

    }
  }

  updateSelectedQuestion(event, index) {

    for (let i = 0; i < this.QuestionsConfig.length; i++) {
      this.calculator_results = null
      this.QuestionsConfig[i].control.reset();
      if (this.QuestionsConfig[i].control_2) {
        this.QuestionsConfig[i].control_2.reset();
      }
      if (this.QuestionsConfig[i].radioButtons) {
        this.QuestionsConfig[i].radioButtons.reset();
      }
      if (event == false && index == i) {
        this.QuestionsConfig[i].isSelected = true;
        this.questionSelectedIndexType = this.QuestionsConfig[i].query_type;
        this.questionSelectedIndex = i;
        // if (
        //   this.questionSelectedIndexType == this.EqueryTypes.maximum_length &&
        //   this.currentSlopeForms.length > 1
        // ) {
        //   this.clearFieldElevation();
        // }
        if (this.questionSelectedIndexType == this.EqueryTypes.matrix) {
          this.initForMatrix()
        }
        else {
          this.initOnSelectQuestion()
        }
        // if (
        //   this.questionSelectedIndexType == (this.EqueryTypes.maximum_length || this.EqueryTypes.matrix) &&
        //   this.currentLateralSectionsForms.length > 1
        // ) {
        //   this.currentLateralSectionsForms.splice(1);
        // }
      } else {
        this.QuestionsConfig[i].isSelected = false;
      }
      if (
        this.QuestionsConfig[i].query_type == this.EqueryTypes.pressure_loss ||
        this.QuestionsConfig[i].query_type ==
        this.EqueryTypes.flow_variation_and_emission_uniformity
      ) {
        this.QuestionsConfig[i].control.setValue(
          this.QuestionsConfig[i].isSelected
        );
      }
    }
  }

  changeFlushingMode(value) {
    this.selectedFlushingMode = value;
  }

  changeFlushingTo(value) {
    this.selectedFlushingTo = value;
  }

  changeCalculationMethod(value) {
    this.selectedCalculationMethod = value;
  }

  changePipeRoughnessSelection(value) {
    this.selectedPipeRoughness = value;
  }

  addLateralSection() {
    let section = this.fb.group({
      pipe_material: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      nominal_diameter: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      wall_thickness: [
        '',
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      internal_diameter: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern(
              '[-+]?[0-9]+(.[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]?)?'
            ),
            Validators.min(0.05),
            Validators.max(100),
          ],
          updateOn: 'blur',
        },
      ],
      length: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.min(0.1),
            Validators.max(1000),
          ],
          updateOn: 'blur',
        },
      ],
    });

    this.currentLateralSectionsForms.push(section);
  }

  initPressureForm() {
    this.pressureForm = this.fb.group({
      end_pressure: [
        '',
        {
          validators: [],
          updateOn: 'blur',
        },
      ],
      inlet_pressure: [
        '',
        {
          validators: [],
          updateOn: 'blur',
        },
      ],
    });
  }

  initMatrixSlopeForm() {
    this.matrixSlopeForm = this.fb.group({
      slopes_matrix: [
        [],
        {
          validators: [Validators.required, Validators.minLength(1)],
        },
      ],
      user_matrix_slope_input: [
        null,
        {
          validators: [Validators.min(-100), Validators.max(100)],
          updateOn: "blur",
        },
      ],
    });
  }

  deleteSection(index) {
    if (this.currentLateralSectionsForms.length > 1) {
      this.currentLateralSectionsForms.splice(index, 1);
    } else {
      this.currentLateralSectionsForms[0].patchValue(
        {
          pipeType: '',
          nominalDiameter: '',
          pipeClass: '',
          pipeInternalDiameter: '',
          pipeSectionLength: this.dataService.totalRealLength,
        },
        { emitEvent: false }
      );
    }
  }

  deleteAllLateralSection() {
    this.currentLateralSectionsForms = [];
    this.addLateralSection();
  }

  isLateralSectionsValid() {
    let boolean = true;
    this.currentLateralSectionsForms.forEach((form) => {
      boolean = boolean && form.valid;
    });

    return boolean;
  }

  isEmitterSectionValid() {
    if (this.questionSelectedIndexType == this.EqueryTypes.matrix) {
      return this.isEmitterFormValidMatrixSpecificValidation();
    }
    return (
      this.pressureForm.valid &&
      this.emitterForm.controls.flow_rate.valid &&
      this.emitterForm.controls.spacing.valid
    );
    // return this.pressureForm.valid && this.emitterForm.valid;
  }

  isEmitterFormValidMatrixSpecificValidation() {
    return (
      this.emitterForm.controls.flow_rate.valid &&
      ((this.emitterForm.controls.user_matrix_spacing_input.value &&
        this.emitterForm.controls.user_matrix_spacing_input.valid) ||
        this.emitterForm.controls.spacing_matrix.valid && !this.emitterForm.controls.user_matrix_spacing_input.value)
    );
  }

  calculateTotalLateralLength() {
    this.dataService.totalLateralLength = 0;
    this.currentLateralSectionsForms.forEach((section) => {
      if (section.controls.length.value) {
        this.dataService.totalLateralLength =
          Number(this.dataService.totalLateralLength) +
          Number(section.controls.length.value);
      }
    });
    this.eleveationUpdateLength()
  }

  eleveationUpdateLength() {
    if (this.dataService.selectedTopography === 'Fixed Slope') {
      this.currentSlopeForms[0].controls.mapLength.setValue(this.dataService.totalLateralLength, { emitEvent: true })
    }
    this.dataService.reloadSlopesIlustration.next();
    setTimeout(() => {
      this.dataService.validationForMapLengthSlope.next();
    }, 0);
  }

  async getLastEmitterPressure() {

    let body;

    if (
      this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value
      )
    ) {

      let section =
        this.currentLateralSectionsForms[
        this.currentLateralSectionsForms.length - 1
        ];
      if (
        this.emitterForm.controls.flow_rate.valid &&
        this.modelForm.valid &&
        section.valid
      ) {

        body = {
          is_inline_emitter: this.dataService.checkIfItsInlineDripper(
            this.modelForm.controls.emitter_type.value.value
          ),
          type: this.modelForm.controls.emitter_type.value.value,
          model: this.dataService.chosen_model_object.model,
          flow_rate: Number(this.emitterForm.controls.flow_rate.value),
          wall_thickness: section.controls.wall_thickness.value,
          nominal_diameter: Number(section.controls.nominal_diameter.value.value),
        };
      }
    } else {

      body = {
        is_inline_emitter: this.dataService.checkIfItsInlineDripper(
          this.modelForm.controls.emitter_type.value.value
        ),
        type: this.modelForm.controls.emitter_type.value.value,
        model: this.dataService.chosen_model_object.model,
        flow_rate: Number(this.emitterForm.controls.flow_rate.value.flow_rate),
      };

    }
    if (body) {

      this.dataService.showLoader = true;
      if (this.offlineService.isItOfflineMode) {
        let response = await this.lateralService.getLastEmitterPressureOffline(body)
        this.dataService.showLoader = false;
        this.setLastEmitterPressureResponse(response);

      } else {

        this.lateralService.getLastEmitterPressure(body).subscribe((resp) => {
          this.dataService.showLoader = false;
          this.setLastEmitterPressureResponse(resp);
        });
      }
    }
  }

  setLastEmitterPressureResponse(resp) {
    if (resp.success) {
      this.dataService.hintMinNumberForOutletPressure =
        resp.result.last_emitter_pressures.min_design_pressure;
      this.dataService.hintMaxNumberForOutletPressure =
        resp.result.last_emitter_pressures.max_design_pressure;
      this.dataService.minValidationForEndletPressure =
        resp.result.last_emitter_pressures.min_technical_pressure;
      this.dataService.maxValidationForEndletPressure =
        resp.result.last_emitter_pressures.max_technical_pressure;
    }
    //set validation for pressure form
    if (this.pressureForm.controls.inlet_pressure.value) {
      this.dataService.updatePressureFormVaidation.next('inlet_pressure');
    } else if (this.pressureForm.controls.end_pressure.value) {
      this.dataService.updatePressureFormVaidation.next('end_pressure');
    } else {
      this.dataService.updatePressureFormVaidation.next('');
    }
  }

  addSlope() {
    let slopeForm: FormGroup = this.fb.group({
      mapLength: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(0),
            Validators.max(1000),
          ],
          updateOn: 'blur',
        },
      ],
      heightDiffM: [
        '',
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
          ],
          updateOn: 'blur',
        },
      ],
      heightDiffP: [
        undefined,
        {
          validators: [
            Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?'),
            Validators.required,
            Validators.min(-100),
            Validators.max(100),
          ],
          updateOn: 'blur',
        },
      ],
      realLength: [
        '',
        {
          validators: [Validators.pattern('[-+]?[0-9]+(.[0-9][0-9]?)?')],
          updateOn: 'blur',
        },
      ],
    });
    this.currentSlopeForms.push(slopeForm);
  }

  initForMatrix() {
    this.show_result_button = false;
    this.dataService.inputs_contents_has_changed = false;
    this.dataService.showAlertMessage = false;
    this.dataService.alert_msg_text = ''
    this.initEmitterFormWithOutModel()
    this.initPressureForm();
    this.initMatrixSlopeForm()
    this.calculator_results = null
    this.currentLateralSectionsForms = [];
    this.addLateralSection();
    this.addMatrixUserInputsListeners()
    this.addMaxAllowedPressureLossListener()
    this.addInletOutletPressureListeners()
  }

  deleteSlope(index) {
    this.currentSlopeForms.splice(index, 1);
  }

  deleteAllSlopes() {
    this.currentSlopeForms.splice(0, this.currentSlopeForms.length);
    this.dataService.reloadSlopesIlustration.next();
  }

  isSlopesFormValid() {
    if (this.questionSelectedIndexType !== this.EqueryTypes.matrix) {
      let bool = true;
      this.currentSlopeForms.forEach((element) => {
        bool = bool && element.valid;
      });
      if (this.dataService.selectedTopography === "Multiple Elevations") {
        bool =
          bool &&
          Number(this.dataService.totalSlopesLength) ===
          Number(this.dataService.totalLateralLength);
      }
      return bool;
    } else {
      return (
        (this.matrixSlopeForm.controls.user_matrix_slope_input.value &&
          this.matrixSlopeForm.controls.user_matrix_slope_input.valid) ||
        (this.matrixSlopeForm.controls.slopes_matrix.valid &&
          !this.matrixSlopeForm.controls.user_matrix_slope_input.value)
      );
    }
  }

  isEmitterFormValid() {
    if (this.questionSelectedIndexType !== this.EqueryTypes.matrix) {
      return this.emitterForm.controls.flow_rate.valid && this.emitterForm.controls.spacing.valid
    } else {
      return (
        (this.emitterForm.controls.user_matrix_spacing_input.value &&
          this.emitterForm.controls.user_matrix_spacing_input.valid) ||
        (this.emitterForm.controls.spacing_matrix.valid &&
          !this.emitterForm.controls.user_matrix_spacing_input.value)
      );
    }
  }

  clearFieldElevation() {
    this.currentSlopeForms = [];
    this.dataService.selectedTopography = 'Flat';
    this.dataService.changeMulitpleToggleButtonChoise.next('Flat');
    this.dataService.showClearPopup = false;
  }

  async calculate() {
    this.analyticsService.sendEvent(
      'lateral',
      'lateral_calculate',
      'calculate_button', this.user_id
    );
    this.calculator_results = null
    this.createBodyForCalculateRequest();
    this.dataService.showLoader = true;
    if (this.questionSelectedIndexType === this.EqueryTypes.matrix) {
      this.dataService.showCalculatingLoader = true
    }
    if (this.offlineService.isItOfflineMode) {
      let response = await this.lateralService.calculateOffline(this.bodyRequestForCalculate)
      this.dataService.showLoader = false;
      this.setCalculateResponse(response)
    } else {
      this.lateralService.calculate(this.bodyRequestForCalculate).subscribe(
        (resp) => {
          this.dataService.showLoader = false;
          this.dataService.showCalculatingLoader = false
          this.setCalculateResponse(resp)
        },
        (err) => {
          this.dataService.showLoader = false;
          if (err.message) {
            this.dataService.error_result_msg = err.message;
          }
          if (err.ErrorToClient) {
            this.dataService.error_result_msg = err.ErrorToClient;
          }
          if (this.dataService.isItMobile) {
            this.BackStep();
          }
          this.dataService.showErrorPopup = true;
        }
      );

    }
  }

  createBodyForCalculateRequest() {
    this.bodyRequestForCalculate = {};
    this.handleBodyRequestCalculationTypeAndCorrespondingParams()

    this.bodyRequestForCalculate.is_flushing =
      this.selectedFlushingMode == 'yes';
    if (this.bodyRequestForCalculate.is_flushing) {
      this.bodyRequestForCalculate.flushing_velocity = Number(
        this.flushingValue.value
      );
      this.bodyRequestForCalculate.is_atmosphere_pressure =
        this.selectedFlushingTo == 'Atmosphere';
      this.bodyRequestForCalculate.is_collector_pressure =
        this.selectedFlushingTo == 'Collector';
    }
    this.bodyRequestForCalculate.friction_loss_formula = Number(
      this.selectedCalculationMethod
    );
    if (
      this.selectedCalculationMethod == '2' &&
      this.selectedPipeRoughness == '2'
    ) {
      this.bodyRequestForCalculate.pipe_roughness_chw = Number(
        Number(this.pipe_roughness_value.value)
      );
    }
    if (this.selectedFlushingTo == 'Other') {
      this.bodyRequestForCalculate.pressure_for_flushing = Number(
        this.flushingToOtherValue.value
      );
    }

    //emitter characteristics
    this.bodyRequestForCalculate.emitter_characteristics = {
      is_inline_emitter: this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value
      ),
      type: this.modelForm.controls.emitter_type.value.value,
      model: this.dataService.chosen_model_object.model,
    };

    //block-characteristics

    this.bodyRequestForCalculate.block_characteristics = {
      inlet_pressure: Number(this.pressureForm.controls.inlet_pressure.value),
      end_pressure: Number(this.pressureForm.controls.end_pressure.value),
      last_lateral_flow_rate: this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value
      ) ? Number(this.emitterForm.controls.flow_rate.value) : Number(this.emitterForm.controls.flow_rate.value.flow_rate),
    };

    if (this.questionSelectedIndexType === this.EqueryTypes.matrix) {
      this.bodyRequestForCalculate.block_characteristics.distance_between_laterals =
        this.emitterForm.controls.user_matrix_spacing_input.value
          ? [
            ...this.emitterForm.controls.spacing_matrix.value,
            Number(this.emitterForm.controls.user_matrix_spacing_input.value),
          ]
          : this.emitterForm.controls.spacing_matrix.value;
      this.bodyRequestForCalculate.block_characteristics.distance_between_laterals.sort(
        (spacingA, spacingB) => spacingA - spacingB
      );
    } else {
      this.bodyRequestForCalculate.block_characteristics.distance_between_laterals = Number(
        this.emitterForm.controls.spacing.value
      );
    }

    //lateral_characteristics
    let sections = [];
    this.currentLateralSectionsForms.forEach((section) => {
      let section_ = {};
      if (
        this.dataService.checkIfItsInlineDripper(
          this.modelForm.controls.emitter_type.value.value
        )
      ) {
        section_ = {
          wall_thickness: section.controls.wall_thickness.value,
          nominal_diameter: Number(section.controls.nominal_diameter.value.value),
          internal_diameter: Number(section.controls.internal_diameter.value),
          section_length: Number(section.controls.length.value),
        };
      } else {
        section_ = {
          pipe_materail: section.controls.pipe_material.value,
          pipe_class: section.controls.wall_thickness.value,
          nominal_diameter: Number(section.controls.nominal_diameter.value.value),
          internal_diameter: Number(section.controls.internal_diameter.value),
          section_length: Number(section.controls.length.value),
        };
      }

      sections.push(section_);
    });
    this.bodyRequestForCalculate.lateral_characteristics = sections;

    this.bodyRequestForCalculate.topography_characteristics = this.buildSlopesForRequestBody();
  }

  handleBodyRequestCalculationTypeAndCorrespondingParams() {
    switch (this.questionSelectedIndexType) {
      case this.EqueryTypes.maximum_length:
        this.setBodyRequestCalculationTypeAndCorrespondingParamsForMaxLengthQuery();
        break;
      case this.EqueryTypes.pressure_loss:

        this.setBodyRequestCalculationTypeForPressureLossQuery();
        break;
      case this.EqueryTypes.flow_variation_and_emission_uniformity:
        this.setBodyRequestCalculationTypeForFvEuForSelectedLateralQuery();
        break;
      case this.EqueryTypes.matrix:
        this.setBodyRequestCalculationTypeForMatrixQuery();
        break;
      default:
        break;
    }
  }

  setBodyRequestCalculationTypeAndCorrespondingParamsForMaxLengthQuery() {
    if (this.dataService.chosen_model_object.isPC) {
      this.bodyRequestForCalculate.calculation_type =
        LateralCalculationTypes.maxLengthForPcEmitters;
      this.bodyRequestForCalculate.max_allowed_pressure_loss = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control.value
      );
    } else {
      this.bodyRequestForCalculate.calculation_type =
        LateralCalculationTypes.maxLengthForNonPcEmitters;
      this.bodyRequestForCalculate.max_allowed_flow_variation = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control.value
      );
      this.bodyRequestForCalculate.max_allowed_emission_uniformity = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control_2.value
      );
    }
  }

  setBodyRequestCalculationTypeForPressureLossQuery() {
    this.bodyRequestForCalculate.calculation_type =
      LateralCalculationTypes.pressureLossForSelectedLateral;
  }

  setBodyRequestCalculationTypeForFvEuForSelectedLateralQuery() {
    this.bodyRequestForCalculate.calculation_type =
      LateralCalculationTypes.fvEuForSelectedLateral;
  }

  setBodyRequestCalculationTypeForMatrixQuery() {
    if (this.dataService.chosen_model_object.isPC) {
      this.bodyRequestForCalculate.calculation_type =
        LateralCalculationTypes.matrixMaxLengthForPcEmitters;
      this.bodyRequestForCalculate.max_allowed_pressure_loss = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control.value
      );
    }
    else {
      this.bodyRequestForCalculate.calculation_type =
        LateralCalculationTypes.matrixMaxLengthForNonPcEmitters;
      this.bodyRequestForCalculate.max_allowed_flow_variation = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control.value
      );
      this.bodyRequestForCalculate.max_allowed_emission_uniformity = Number(
        this.QuestionsConfig[this.questionSelectedIndex].control_2.value
      );
    }
  }

  buildSlopesForRequestBody() {
    if (this.questionSelectedIndexType == this.EqueryTypes.matrix) {
      return this.buildSlopesForMatrix();
    } else {
      return this.buildSlopes();
    }
  }

  buildSlopesForMatrix() {
    const slopes = this.matrixSlopeForm.controls.user_matrix_slope_input.value
      ? [
        ...this.matrixSlopeForm.controls.slopes_matrix.value,
        Number(this.matrixSlopeForm.controls.user_matrix_slope_input.value),
      ]
      : this.matrixSlopeForm.controls.slopes_matrix.value;

    slopes.sort((slopeA, slopeB) => slopeA - slopeB);
    return slopes;
  }

  buildSlopes() {
    const slopes = []
    if (this.currentSlopeForms.length === 0) {
      slopes.push({
        length_on_map: Number(this.dataService.totalLateralLength),
        height_diff_meters: 0,
        heigth_diff_precent: 0,
        real_length: Number(this.dataService.totalLateralLength),
      });
    } else {
      this.currentSlopeForms.forEach((slope) => {
        slopes.push({
          length_on_map: Number(slope.get("mapLength").value),
          height_diff_meters: Number(slope.get("heightDiffM").value),
          heigth_diff_precent: Number(slope.get("heightDiffP").value),
          real_length: Number(slope.get("realLength").value),
        });
      });
    }
    return slopes
  }

  setCalculateResponse(resp) {
    this.show_result_button = true;
    this.dataService.inputs_contents_has_changed = false
    if (resp.success) {
      let first_time_calculate = this.calculator_results ? false : true;
      this.calculator_results = undefined;

      if (this.questionSelectedIndexType === this.EqueryTypes.matrix) {
        const inletPressure = Number(
          this.pressureForm.controls.inlet_pressure.value
        );
        const endPressure = Number(
          this.pressureForm.controls.end_pressure.value
        );
        this.updateMatrixResults(inletPressure, endPressure, resp.result);
      }

      this.calculator_results = resp.result;

      setTimeout(() => {
        this.dataService.reloadIlustrationResult.next();
        this.dataService.reloadGraphResult.next();
      });

      this.dataService.reloadPipes.next();
      if (!first_time_calculate) {
        this.dataService.reloadResults.next();
      }
    }
  }

  updateMatrixResults(inletPressure, endPressure, results) {
    if (endPressure) {
      results.calculationInfo.endPressure = endPressure
    }
    const { endPressure: _endPressure } = results.calculationInfo
    results.calculationInfo.endPressure = _endPressure.toFixed(2)
    results.calculationInfo.inletPressure = inletPressure;
  }


  scrollTopResult() {
    const element = document.querySelector('#scroll');
    element.scrollIntoView();
  }

  changeFromResultToAnotherSection() {
    this.show_section = this.dataService.chosedSectionToChangeTo;
    this.dataService.showWarningPopup = false;
  }

  BackStep() {
    switch (this.show_section) {
      case this.CALCULATOR_SECTION.QUESTION:
        this.show_section = this.CALCULATOR_SECTION.EMITTER;
        break;
      case this.CALCULATOR_SECTION.LATERAL:
        this.show_section = this.CALCULATOR_SECTION.QUESTION;
        break;
      case this.CALCULATOR_SECTION.BLOCK:
        this.show_section = this.CALCULATOR_SECTION.LATERAL;
        break;
      case this.CALCULATOR_SECTION.RESULT:
        this.show_section = this.CALCULATOR_SECTION.BLOCK;
        this.analyticsService.sendEvent(
          'lateral',
          'step_back_after_calculation',
          this.CALCULATOR_SECTION.BLOCK, this.user_id
        );

        break;
      default:
        this.show_section = this.CALCULATOR_SECTION.EMITTER;
        break;
    }
  }

  nextStepMobile() {
    switch (this.show_section) {
      case this.CALCULATOR_SECTION.EMITTER:
        this.show_section = this.CALCULATOR_SECTION.QUESTION;
        this.next_to_queries();
        break;
      case this.CALCULATOR_SECTION.QUESTION:
        this.show_section = this.CALCULATOR_SECTION.LATERAL;
        break;
      case this.CALCULATOR_SECTION.LATERAL:
        this.show_section = this.CALCULATOR_SECTION.BLOCK;
        this.calculateTotalLateralLength();
        break;
      case this.CALCULATOR_SECTION.BLOCK:
        this.show_section = 'result';
        this.calculate();
        break;
      default:
        break;
    }
  }

  validationByStep() {
    switch (this.show_section) {
      case this.CALCULATOR_SECTION.EMITTER:
        return !this.emitterForm.valid || !this.modelForm.valid;
        break;
      case this.CALCULATOR_SECTION.QUESTION:
        return (
          (!this.QuestionsConfig[0].control.valid ||
            !this.QuestionsConfig[0].control_2?.valid) &&
          !this.QuestionsConfig[1].control.valid
        );
        break;
      case this.CALCULATOR_SECTION.LATERAL:
        return (
          !this.isLateralSectionsValid() ||
          !this.pressureForm.valid ||
          !this.emitterForm.valid
        );
        break;
      case this.CALCULATOR_SECTION.BLOCK:
        return !(
          this.dataService.selectedTopography === 'Flat' ||
          this.isSlopesFormValid()
        );

        break;
      default:
        break;
    }
  }

  isEmitterBlockValid() {
    return this.modelForm.valid && ((this.emitterForm.controls.flow_rate.valid && this.emitterForm.controls.spacing.valid && !this.dataService.checkIfItsInlineDripper(
      this.modelForm.controls.emitter_type.value.value)) || this.dataService.checkIfItsInlineDripper(
        this.modelForm.controls.emitter_type.value.value)
    )
  }

  public isModelExist(): ValidatorFn {
    return (control: AbstractControl): {} | null => {
      //set model and value as an object
      let model_label = String(control.value)
      let objectValue = this.dataService?.model_options ? this.dataService.model_options.filter(model => {
        return model.modelLabel === model_label
      })[0] : null

      if (objectValue) {
        return true;
      } else {
        return of(false);
      }
    }
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
