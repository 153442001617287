import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from 'src/app/services/data.service';
import { PdfService } from 'src/app/services/pdf.service';
import { Ecalculator, EtransaltionGroups } from 'src/app/shared/enum';
import { TextService } from 'src/app/services/text.service';
import { CALCULATOR_SECTION } from 'src/app/shared/consts';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { GraphService } from 'src/app/services/graph.service';

@Component({
  selector: 'app-block-result-mobile',
  templateUrl: './block-result-mobile.component.html',
  styleUrls: ['./block-result-mobile.component.scss'],
})
export class BlockResultMobileComponent implements OnInit {
  EtransaltionGroups = EtransaltionGroups;
  @Input() emitterForm;
  pipes_diameter_text;
  @Input() Lateralsections;
  @Input() results;
  @Input() title;
  @Input() calculatorIndex;
  @Input() queryIndex;
  @Input() show_section;
  @Input() columnsDisplayNames;
  @Input() displayedColumns: string[];
  @Input() topResultBody: any[];
  @Input() extra_section_flushing: boolean = false
  @Input() user_id //for analytics events
  calculation_success;
  lateral_text;
  dataSource: any = [];
  fillerList = false;
  fillerListArray: any[] = [, ,];
  ELEMENT_DATA: any[] = [];
  pipe_rows;
  warnings: any = [];
  colorRedInlet = false;
  colorRedEnd = false;
  warningIdOne = false;
  flushingResultBody: any[];
  flushingResultGivenInletPressure: any[];
  CALCULATOR_SECTION = CALCULATOR_SECTION;
  model_text;
  calculator_name = ''
  flushing_subtitle_text
  sections_title: string

  //ilustration
  left_side_text
  right_side_text

  //pdf
  @Input() ElevationsForm;
  @Input() elevationTablePDF;

  constructor(
    public dataService: DataService,
    public pdf: PdfService,
    public textService: TextService,
    public analyticsService: GoogleAnalyticsService,
    public grpahService: GraphService
  ) {
    this.dataService.exportResultToPdf.subscribe((res) => {
      this.exportToPDF();
    });
    this.dataService.reloadResults.subscribe((res) => {
      setTimeout(() => {
        this.initialResult();
      });
    });
  }

  buildLateralText() {
    this.model_text = this.dataService.chosen_model;
    this.lateral_text = ', ';
    if (this.Lateralsections.length == 1) {
      if (this.Lateralsections[0].controls.pipe_material.value) {
        this.lateral_text =
          this.lateral_text +
          this.Lateralsections[0].controls.pipe_material.value +
          ', ';
      }
      this.lateral_text =
        this.lateral_text +
        this.Lateralsections[0].controls.nominal_diameter.value.value +
        ' ' +
        this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          'lateral_result_subtitle_diameter'
        );
      this.lateral_text =
        this.lateral_text +
        ' ' +
        this.textService.addMesaureUnitsToLabel('', 'mm') +
        ', ' +
        this.Lateralsections[0].controls.wall_thickness.value +
        ' ' +
        this.textService.getText(
          this.EtransaltionGroups.LATERAL,
          this.dataService.checkIfItsInlineDripper(
            this.dataService.chosen_emitter_type
          )
            ? 'lateral_results_wall_thickness'
            : 'lateral_results_sections_class'
        ) +
        ', ';
    }
    this.lateral_text =
      this.lateral_text + (this.dataService.checkIfItsInlineDripper(
        this.dataService.chosen_emitter_type
      ) ? this.emitterForm.controls.flow_rate.value : this.emitterForm.controls.flow_rate.value.flow_rate_label) + ' ';
    this.lateral_text =
      this.lateral_text +
      this.textService.addMesaureUnitsToLabel('', 'lh') +
      ' ' +
      this.textService.getText(
        this.EtransaltionGroups.LATERAL,
        'lateral_result_subtitle_flow_rate'
      ) +
      ', ' +
      this.emitterForm.controls.spacing.value +
      ' ';
    this.lateral_text =
      this.lateral_text +
      this.textService.addMesaureUnitsToLabel('', 'm') +
      ' ' +
      this.textService.getText(
        this.EtransaltionGroups.LATERAL,
        'lateral_result_subtitle_spacing'
      );
  }

  ngOnInit(): void {
    switch (this.calculatorIndex) {
      case Ecalculator.lateral:
        this.calculator_name = 'lateral'
        this.sections_title = this.textService.getText(
          EtransaltionGroups.MOBILE,
          "lateral_result_table_title_mobile"
        )
        break;
      case Ecalculator.submain:
        this.calculator_name = 'submain'
        this.sections_title = this.textService.getText(
          EtransaltionGroups.MOBILE,
          "submain_result_table_title_mobile"
        )
        break;
      case Ecalculator.mainline:
        this.calculator_name = 'mainline'
        this.sections_title = this.textService.getText(
          EtransaltionGroups.MAINLINE,
          "mainline_result_table_title_mobile"
        )
        break;
      default:
        break;
    }
    this.initialResult();
  }

  initialResult() {
    this.calculation_success = this.results.calculation_success;
    if (this.calculation_success) {
      this.pipe_rows = this.results.calculation_extra_details.pipes;
      this.warnings = this.results.calculation_results.warnings;
      this.setFlushingResultText();
      this.setflushingResultGivenInletPressureText();
      if (this.topResultBody) {
        this.createTopResultBody();
      }
      this.checkValidationForInletEndPressutre();
      if (this.dataService.selectedTopography !== 'Multiple Elevations'
        && this.results.calculation_extra_details.slopes 
        && this.results.calculation_extra_details.slopes.length > 0) {
        this.results.calculation_results.slope_percent = this.results.calculation_extra_details.slopes[0].HeightDiffPrecent
      }
      this.pipe_rows.forEach((pipe, index) => {
        switch (this.calculatorIndex) {
          case Ecalculator.lateral:
            this.buildLateralText();
            this.ELEMENT_DATA.push({
              pipe_section:
                this.textService.getText(this.EtransaltionGroups.LATERAL, "lateral_summary__sectionss") + ' ' + this.dataService.sectionItems[index].letter,
              nominal_diameter: pipe['NominalDiameter'],
              wall_thickness: pipe['Class'],
              length: pipe['SectionLength'],
              pressure_loss: pipe['PressureLoss'],
              max_pressure: pipe['MaxPressure'],
              min_pressure: pipe['MinPressure'],
              velocity: pipe['MaxVelocity'],
              flow_rate: pipe['MaxFlowRate'],
              color: this.dataService.colors[index],
            });
            break;
          case Ecalculator.submain:
            this.ELEMENT_DATA.push({
              pipe_section: pipe['Type'] + ' ' + pipe['Class'],
              pressure_loss: pipe['PressureLoss'],
              max_pressure: pipe['MaxPressure'],
              min_pressure: pipe['MinPressure'],
              pipe_length: pipe['SectionLength'],
              pipe_diameter: pipe['NominalDiameter'],
              inlet_presure: pipe['InletPressure'],
              end_pressure: pipe['EndPressure'],
              flow_rate: pipe['MaxFlowRate'],
              velocity: pipe['MaxVelocity'],
              color: this.dataService.colors[index],
            });
            break;
          case Ecalculator.mainline:
            this.ELEMENT_DATA.push({
              pipe_section: this.textService.getText(this.EtransaltionGroups.MAINLINE, "mainline_results_section_label") + ' ' + this.dataService.sectionItems[index].letter,
              material_type: pipe['Type'],
              nominal_diameter: pipe['NominalDiameter'],
              pipe_class: pipe['Class'],
              length: pipe['SectionLength'],
              pressure_loss: pipe['PressureLoss'],
              inlet_pressure: pipe['InletPressure'],
              end_pressure: pipe['EndPressure'],
              max_pressure: pipe['MaxPressure'],
              min_pressure: pipe['MinPressure'],
              flow_rate: pipe['AtomicFlowRate'],
              accumulated_flow_rate: pipe['MaxFlowRate'],
              velocity: pipe['MaxVelocity'],
              color: this.dataService.colors[index],
            });
            break;
          default:
            break;
        }
      });
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.setCSScolumn();
    } else {
      this.dataService.error_result_msg =
        this.results.calculation_results.errors[0].error;
      this.dataService.error_id_msg =
        this.results.calculation_results.errors[0].Id;
      setTimeout(() => {
        this.dataService.showErrorPopup = true;
      });
    }
  }
  setGraphs(isOneYAxis) {
    this.dataService.showGraphPopup = true;
    this.grpahService.oneYAxis = isOneYAxis
    this.grpahService.length = this.calculatorIndex == Ecalculator.submain
      ? this.results.calculation_results.submain_length
      : this.results.calculation_results.total_length
    this.grpahService.calculatorIndex = this.calculatorIndex
    this.grpahService.graphData = this.results.graphs_data
    this.grpahService.pipes_info = this.results.calculation_extra_details.pipes
    if (isOneYAxis) {
      this.grpahService.title = this.textService.getText(
        this.calculatorIndex == Ecalculator.submain
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == Ecalculator.submain
          ? "submain_results_graph2_title"
          : "lateral_results_graph_2_title"
      )
      this.grpahService.legend_pressure = this.textService.getText(
        this.calculatorIndex == Ecalculator.submain
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == Ecalculator.submain
          ? "submain_results_graph2_y_axis"
          : "lateral_results_graph_2_flow_rate"
      )
      this.grpahService.legend_elevation = undefined
      this.grpahService.legend_max_pressure = undefined

      this.grpahService.graphID = 'flow_rate_popup'
    } else {
      this.grpahService.title = this.textService.getText(
        this.calculatorIndex == Ecalculator.submain
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == Ecalculator.submain
          ? "submain_results_graph1_title"
          : "lateral_results_graph_1_title"
      )
      this.grpahService.legend_pressure = this.textService.getText(
        this.calculatorIndex == Ecalculator.submain
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == Ecalculator.submain
          ? "submain_results_graph1_legend_pressure"
          : "lateral_results_graph1_legend_pressure"
      )
      this.grpahService.legend_elevation = this.textService.getText(
        this.calculatorIndex == Ecalculator.submain
          ? EtransaltionGroups.SUBMAIN
          : EtransaltionGroups.LATERAL,
        this.calculatorIndex == Ecalculator.submain
          ? "submain_results_graph1_y_axis_2"
          : "lateral_results_graph_1_elevation"
      )
      this.grpahService.legend_max_pressure =
        this.textService.getText(
          this.calculatorIndex == Ecalculator.submain
            ? EtransaltionGroups.SUBMAIN
            : EtransaltionGroups.LATERAL,
          this.calculatorIndex == Ecalculator.submain
            ? "submain_results_graph1_legend_max_pressure"
            : "lateral_results_graph1_legend_max_pressure"
        )
      this.grpahService.graphID = 'pressure_popup'
    }

  }
  setIlusrtationText() {
    switch (this.calculatorIndex) {
      case Ecalculator.submain:
        this.right_side_text = this.textService.addMesaureUnitsToLabel(
          this.textService.getText(
            EtransaltionGroups.SUBMAIN,
            "submain_s2_elevation_graph"
          ),
          "m_pressure"
        )
        break;
      case Ecalculator.mainline:
        this.right_side_text = this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          "mainline_ilustration_x_length"
        )
        this.left_side_text = this.textService.getText(
          this.EtransaltionGroups.MAINLINE,
          "mainline_ilustration_x_water_source"
        )
        break;

      default:
        break;
    }


  }
  ngAfterViewInit() {
    const element = document.querySelector('#top');
    element.scrollIntoView();
  }

  setFlushingResultText() {
    this.flushingResultBody = this.getFlushingText(false);
  }

  setflushingResultGivenInletPressureText() {
    this.flushingResultGivenInletPressure = this.getFlushingText(true);
  }

  getFlushingText(isFlushingRusltsGivenInletPressure: boolean) {
    const translationCalcGroups = [
      this.EtransaltionGroups.SUBMAIN,
      this.EtransaltionGroups.LATERAL,
      this.EtransaltionGroups.MAINLINE
    ];
    const translationGroupId = translationCalcGroups[this.calculatorIndex - 1];

    let fieldsForFlushingText = [
      {
        translationIdStr: [
          'submain_results_flushing_inlet_flow_rate',
          'lateral_results_flushing_inlet_flow_rate',
          'mainline_results_flushing_inlet_flow_rate',
        ],
        metricUnits: Ecalculator.lateral === this.calculatorIndex ? 'lh' : 'm3h',
        key: 'inlet_flow_rate',
      },
      {
        translationIdStr: [
          'submain_results_flushing_max_pressure',
          'lateral_results_flushing_max_pressure',
          'mainline_results_flushing_max_pressure',
        ],
        metricUnits: 'm_pressure',
        key: 'max_pressure',
      },
      {
        translationIdStr: [
          'submain_results_flushing_pressure_loss',
          'lateral_results_flushing_pressure_loss',
          'mainline_results_flushing_pressure_loss',
        ],
        metricUnits: 'm_pressure',
        key: 'pressure_loss',
      },
      {
        translationIdStr: [
          'submain_results_flushing_inlet_pressure',
          'lateral_results_flushing_inlet_pressure',
          'mainline_results_flushing_inlet_pressure',
        ],
        metricUnits: 'm_pressure',
        key: 'inlet_pressure',
      },
      {
        translationIdStr: [
          'submain_results_flushing_max_velocity',
          'lateral_results_flushing_max_velocity',
          'mainline_results_flushing_max_velocity',
        ],
        metricUnits: 'ms',
        key: 'flushing_velocity',
      },
      //-------- New 3 labels
      {
        translationIdStr: [
          'submain_results_flushing_end_velocity',
          'lateral_results_flushing_end_velocity',
          'mainline_results_flushing_end_velocity',
        ],
        metricUnits: 'ms',
        key: 'flushing_end_velocity',
      },
      {
        translationIdStr: [
          'submain_results_flushing_end_flow_rate',
          'lateral_results_flushing_end_flow_rate',
          'mainline_results_flushing_end_flow_rate',
        ],
        metricUnits: Ecalculator.lateral === this.calculatorIndex ? 'lh' : 'm3h',
        key: 'last_flow_rate',
      },
      {
        translationIdStr: [
          'submain_results_flushing_end_pressure',
          'lateral_results_flushing_end_pressure',
          'mainline_results_flushing_end_pressure',
        ],
        metricUnits: 'm',
        key: 'end_pressure',
      },
    ];

    // Total length box
    if (isFlushingRusltsGivenInletPressure) {
      fieldsForFlushingText.push({
        translationIdStr: [
          'submain_results_flushing_given_inlet_pressure_total_length',
          'lateral_results_flushing_given_inlet_pressure_total_length',
          'mainline_results_flushing_given_inlet_pressure_total_length',
        ],
        metricUnits: 'm',
        key: 'totalLength',
      });
    }

    return fieldsForFlushingText.map((boxFields) => {
      return this.getFlushingBoxText({ ...boxFields, translationGroupId });
    });
  }

  getFlushingBoxText({
    translationGroupId,
    translationIdStr,
    metricUnits,
    key,
  }) {
    return {
      name: this.textService.addMesaureUnitsToLabel(
        this.textService.getText(
          translationGroupId,
          translationIdStr[this.calculatorIndex - 1]
        ),
        metricUnits
      ),
      key,
    };
  }

  createTopResultBody() {
    //delete pipe diameter result
    this.pipes_diameter_text = this.textService.addMesaureUnitsToLabel(
      this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_results_pipe_sections_diameter'
      ),
      'mm'
    );
    let diameter_index;
    this.topResultBody.find((element, i) => {
      if (element.name.includes(this.pipes_diameter_text)) {
        diameter_index = i;
      }
    });
    if (diameter_index >= 0) {
      this.topResultBody.splice(diameter_index, 1);
    }

    //change to second from top
    if (this.topResultBody[4].key.includes('pressure_loss')) {
      let loss_pressure_element = this.topResultBody[4];
      this.topResultBody.splice(4, 1);
      this.topResultBody.splice(1, 0, loss_pressure_element);
    }

    if (this.queryIndex == 0) {
      let index: number;
      this.topResultBody.find((element, i) => {
        if (element.key.includes('pressure_loss')) {
          index = i;
        }
      });
      if (index && index > 0) {
        [this.topResultBody[0], this.topResultBody[index]] = [
          this.topResultBody[index],
          this.topResultBody[0],
        ];
      }
    }
    if (this.queryIndex == 1 && this.pipe_rows.length > 0) {
      let value = '';

      this.pipe_rows.forEach((element, index) => {
        if (index > 0) {
          value = value + ' , ' + element.NominalDiameter;
        } else {
          value = element.NominalDiameter;
        }
      });
      this.pipes_diameter_text = this.textService.addMesaureUnitsToLabel(
        this.textService.getText(
          this.EtransaltionGroups.SUBMAIN,
          'submain_results_pipe_sections_diameter'
        ),
        'mm'
      );

      this.topResultBody.unshift({
        name: this.pipes_diameter_text,
        key: value,
      });
    }
    if (this.queryIndex == 2) {
      let index;
      let total_length_text = this.textService.getText(
        this.EtransaltionGroups.SUBMAIN,
        'submain_results_total_length'
      );
      this.topResultBody.find((element, i) => {
        if (element.name.includes(total_length_text)) {
          index = i;
        }
      });

      if (index) {
        [this.topResultBody[0], this.topResultBody[index]] = [
          this.topResultBody[index],
          this.topResultBody[0],
        ];
      }
    }
    if (this.queryIndex == 3) {
      let index_flow: number;
      let index_emission: number;
      this.topResultBody.find((element, i) => {
        if (element.key.includes('flow_variation')) {
          index_flow = i;
        }
      });
      if (index_flow && index_flow > 0) {
        [this.topResultBody[0], this.topResultBody[index_flow]] = [
          this.topResultBody[index_flow],
          this.topResultBody[0],
        ];
      }
      this.topResultBody.find((element, i) => {
        if (element.key.includes('emission_uniformity')) {
          index_emission = i;
        }
      });
      if (index_emission && index_emission != 1) {
        [this.topResultBody[1], this.topResultBody[index_emission]] = [
          this.topResultBody[index_emission],
          this.topResultBody[1],
        ];
      }
    }
  }

  setCSScolumn() {
    switch (this.queryIndex) {
      case 0:
        document.documentElement.style.setProperty(
          '--color_pressure',
          'var(--netafim-blue)'
        );
        document.documentElement.style.setProperty(
          '--color_diammeter',
          'var(--netafim-black)'
        );
        document.documentElement.style.setProperty('--color_length', 'var(--netafim-black)');
        document.documentElement.style.setProperty(
          '--color_for_second_result_title',
          '#6e6e6e'
        );
        document.documentElement.style.setProperty(
          '--color_for_second_result_value',
          '#2d2d2d'
        );
        document.documentElement.style.setProperty(
          '--background_for_second_result',
          '#ffffff'
        );
        break;
      case 1:
        document.documentElement.style.setProperty(
          '--color_diammeter',
          'var(--netafim-blue)'
        );
        document.documentElement.style.setProperty(
          '--color_pressure',
          'var(--netafim-black)'
        );
        document.documentElement.style.setProperty('--color_length', 'var(--netafim-black)');
        document.documentElement.style.setProperty(
          '--color_for_second_result_title',
          '#6e6e6e'
        );
        document.documentElement.style.setProperty(
          '--color_for_second_result_value',
          '#2d2d2d'
        );
        document.documentElement.style.setProperty(
          '--background_for_second_result',
          '#ffffff'
        );
        break;
      case 2:
        document.documentElement.style.setProperty(
          '--color_diammeter',
          'var(--netafim-black)'
        );
        document.documentElement.style.setProperty(
          '--color_pressure',
          'var(--netafim-black)'
        );
        document.documentElement.style.setProperty('--color_length', 'var(--netafim-blue)');
        document.documentElement.style.setProperty(
          '--color_for_second_result_title',
          '#6e6e6e'
        );
        document.documentElement.style.setProperty(
          '--color_for_second_result_value',
          '#2d2d2d'
        );
        document.documentElement.style.setProperty(
          '--background_for_second_result',
          '#ffffff'
        );
        break;
      case 3:
        document.documentElement.style.setProperty(
          '--color_diammeter',
          'var(--netafim-black)'
        );
        document.documentElement.style.setProperty(
          '--color_pressure',
          'var(--netafim-black)'
        );
        document.documentElement.style.setProperty('--color_length', 'var(--netafim-black)');
        document.documentElement.style.setProperty(
          '--color_for_second_result_title',
          'var(--netafim-blue)'
        );
        document.documentElement.style.setProperty(
          '--color_for_second_result_value',
          'var(--netafim-blue)'
        );
        document.documentElement.style.setProperty(
          '--background_for_second_result',
          '#eff7ff'
        );
        break;

      default:
        break;
    }
  }

  checkValidationForInletEndPressutre() {
    if (this.warnings) {
      let warning = this.warnings.filter((value) => {
        value.Id == 1;
      });
      if (warning.length > 0) {
        this.warningIdOne = true;
      } else {
        this.warningIdOne = false;
      }

      if (
        this.warningIdOne &&
        Number(this.results.calculation_results.end_pressure) >
        Number(this.results.calculation_results.inlet_pressure)
      ) {
        this.colorRedInlet = false;
        this.colorRedEnd = true;
      } else if (
        this.warningIdOne &&
        Number(this.results.calculation_results.end_pressure) <
        Number(this.results.calculation_results.inlet_pressure)
      ) {
        this.colorRedInlet = true;
        this.colorRedEnd = false;
      } else {
        this.colorRedInlet = false;
        this.colorRedEnd = false;
      }
    }
  }



  exportToPDF() {
    this.dataService.showLoader = true;
    let eventName
    switch (this.calculatorIndex) {
      case Ecalculator.submain:
        eventName = 'submain_export_pdf'
        break;
      case Ecalculator.lateral:
        eventName = 'lateral_export_pdf'
        break;
      case Ecalculator.mainline:
        eventName = 'mainline_export_pdf'
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.analyticsService.sendEvent(
        this.calculator_name,
        eventName,
        'export_pdf_button', this.user_id
      );
      let content: Element = document.getElementById('pdf-to-export-mobile');
      let title = 'results';
      this.pdf.generatePDF(content, title);
    });
  }

  colorTextInRed(key) {
    return (
      this.colorPressureResultInRed(key) ||
      this.colorInletPressureResultInRed(key)
    );
  }

  colorPressureResultInRed(key) {
    return (
      ((Number(this.results.calculation_results.end_pressure) <= 0 ||
        this.colorRedEnd) &&
        key == 'end_pressure') ||
      ((Number(this.results.calculation_results.inlet_pressure) <= 0 ||
        this.colorRedInlet) &&
        key == 'inlet_pressure')
    );
  }

  colorInletPressureResultInRed(key) {
    const foundWarning = this.warnings.some((warning) => warning.Id === 1);
    return foundWarning && key === 'inlet_pressure';
  }

  colorPressureTableInRed(currentBox, tableRow) {
    const inlet_pressure_value: number = tableRow.InletPressure
    const end_pressure_value: number = tableRow.EndPressure
    const maxPressureDisplay: boolean = currentBox.name === 'max_pressure'
    const endPressureDisplay: boolean = currentBox.name === 'end_pressure'
    const inletPressureDisplay: boolean = currentBox.name === 'inlet_pressure'
    const isInletBiggerThanEnd: boolean = inlet_pressure_value > end_pressure_value
    return maxPressureDisplay || (endPressureDisplay && !isInletBiggerThanEnd) || (inletPressureDisplay && isInletBiggerThanEnd)
  }
}
